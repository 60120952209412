import { Show, batch, createEffect, onCleanup, onMount, splitProps, untrack } from "solid-js";
import { AGuardTriggerProps, AGuardState, GuardViewProps, AGuardHelperProps, AGuardEventHelperProps } from "../models";
import { Route, Routes, useLocation, useNavigate, Location } from "@solidjs/router";
import { Atom, SHARED_UTILS, state } from ":mods";
import { checkGuardSteps } from "../methods";
import { _route_actions, actions } from "../store";

const { isMatch, isWildcard, matcher } = SHARED_UTILS.wildcard;
let app_first_loaded = true;
let prev_location: Location<unknown> = undefined;
/** record to keep track of which bases are ignored when other bases are loaded and/or match base pattern */
const route_record: Record<string, string[]> = {
  // "/auth": ["/auth*"],
  // "/admin": ["/admin*"],
};
export function Guarded(props: GuardViewProps) {
  const [local] = splitProps(props, ["layout", "children", "guard", "errorElement", "base", "events"]);
  if (isWildcard(local.base)) {
    throw new Error(
      `GuardedRoute: route must not include any wildcards -> ${local.base} please check your setup and change it!`
    );
  } else if (route_record[local.base]) {
    throw new Error(`GuardedRoute: route already exists -> ${local.base} please check your setup and change it!`);
  }
  const $loader_msg = state.create(undefined as string);
  const $load_route = state.create(false);
  const $guard_passed = state.create(false);
  const $location = useLocation();
  const $navigate = actions.navigateHref;
  //
  // let prev_location: typeof $location = undefined;
  let first_load = true;
  route_record[local.base] = [`${SHARED_UTILS.removeTrailingSlash(local.base)}*`];

  // console.log("setup abstract route :: ", local.base, " :: ", route_record);
  onMount(() => {
    for (const record_base in route_record) {
      if (local.base === record_base) {
        continue;
      }
      const other_base_pattern = route_record[record_base][0];
      const local_base_pattern = route_record[local.base][0];
      if (isMatch(other_base_pattern, local_base_pattern)) {
        // console.log("base pattern :: ", local.base, " :: includes :: ", local_base_pattern, " :: ", other_base_pattern);
        route_record[local.base].push(`!${other_base_pattern}`);
      }
    }
    // console.log("mounted abstract route :: ", local.base, " :: ", route_record);
  });
  onCleanup(() => {
    /**
     * ! this affects developer experience only, not production.
     * the order to after the initial setup is
     * setup all functions -> run on mount for all functions -> run effects for all functions
     * but after on clean up is ran it goes
     * run clean up per function -> run setup per function -> run on mount per function -> run setup per functions -> run effect per function
     * which in no way allows us to recapture all bases for route_record to setup matching patterns for this base.
     * so in short, developer needs to refresh page manually.
     */
    // route_record[local.base] = undefined;
    // $load_route.set(false);
    // unloadRoute();
    // console.log("cleaned abstract route :: ", local.base);
  });

  createEffect(async () => {
    const { pathname } = $location;
    const should_mount = isMatch(pathname, route_record[local.base]);
    // console.log(
    //   "abstract route effect for :: ",
    //   local.base,
    //   " :: should mount -> ",
    //   should_mount,
    //   " :: match routes -> ",
    //   route_record[local.base]
    // );

    untrack(async () => {
      if (!should_mount) {
        first_load = true;
        batch(() => {
          if ($load_route.unwrap) {
            $load_route.set(false);
            // $loader_msg.set(`loading platform...`);
          }
          unloadRoute();
        });
      } else {
        // $guard_passed.set(false);
        _route_actions.setGlobalBaseFromGuard(local.base);
        let navigated = false;
        const state: AGuardState = app_first_loaded ? "app_init" : first_load ? "first_load" : "each_route";
        const triggers: AGuardTriggerProps = {
          loadRoute,
          navigate: (props) => {
            const nav_props: Exclude<typeof props, string> = {
              path: typeof props === "string" ? props : props.path ?? "",
              base: typeof props === "string" ? local.base : props.base ?? local.base,
            };
            const error = $navigate(nav_props);
            if (!error) {
              navigated = true;
            }
            return error;
          },
        };
        const helpers: AGuardHelperProps = {
          state,
          base: local.base,
          isBaseRoute: $location.pathname === local.base,
          location: {
            current: $location,
            previous: prev_location,
          },
          routeMatchBase: (base) => {
            return isMatch($location.pathname, base + "*");
          },
          routeMatch: (route, pathname?: string) => {
            if (route === local.base) {
              console.error(
                "GuardedRoute: use of base route as path in routeMatch is prohibited, please use isBaseRoute instead."
              );
              return false;
            }
            const merged_href = SHARED_UTILS.mergeRouteHref({ base: local.base }, route);
            const full_path = SHARED_UTILS.populateRouteHref(merged_href);
            const current_route_is_subset_of_full_path = isMatch(pathname ?? $location.pathname, full_path + "*");
            // console.log(
            //   "route match current route is subset ::",
            //   full_path,
            //   " :: ",
            //   current_route_is_subset_of_full_path
            // );

            return current_route_is_subset_of_full_path;
          },
          routeMatchPathname: (path) => {
            let result: boolean = isMatch(path, $location.pathname + "*");
            // console.log("route match pathname ::", route_record[local.base], " :: base to r -> ", result);
            return result;
          },
        };

        if (state === "each_route") {
          local.events?.onRouteChange?.(helpers);
        } else {
          local.events?.onRouteFirstLoad?.(helpers);
        }

        // console.log("checking route guards for :: ", local.base, " :: ", state, " :: ", event_helpers.isBaseRoute);
        // $guard_passed.set(true);
        console.log(
          "checking route guards for :: ",
          local.base,
          " :: exactly :: ",
          $location.pathname,
          " :: state :: ",
          state,
          " :: $loader_msg :: ",
          $loader_msg.unwrap,
          " :: $guard_passed :: ",
          $guard_passed.unwrap
        );

        if (!$load_route.unwrap) {
          $load_route.set(true);
        }
        first_load = false;
        app_first_loaded = false;
        await checkGuardSteps({
          steps: local.guard?.steps,
          events: {
            next: (step) => {
              $loader_msg.set(step.loaderMsg);
              // console.log("next step is ", step);
              return helpers;
            },
            success: () => {
              // console.log("surely success !!");
              if (!local.events?.onGuardSuccess) {
                loadRoute();
              } else {
                local.events?.onGuardSuccess?.(triggers, helpers);
                let error_msg = _route_actions.getGobalRouteError();
                if (!error_msg) {
                  if (!navigated && !$guard_passed.unwrap) {
                    error_msg = `trigger.loadRoute haven't been called from within ${local.base} base route onGuardSuccess`;
                  }
                }
                if (error_msg) {
                  $loader_msg.set(error_msg);
                }
              }
            },
            failure: (props, key) => {
              // console.log("surely error !!");
              let error_msg = undefined;
              if (!local.events?.onGuardFailure) {
                error_msg = `${props.error.error}`;
                error_msg += `\n this is unhandled guard error in ${local.base} base route controller`;
                error_msg += `\n this can be handled by defining onGuardError under events prop`;
              } else {
                local.events?.onGuardFailure?.(triggers, { ...props, ...helpers });
                if (_route_actions.getGobalRouteError()) {
                  console.log("route failure ::: ", $guard_passed.unwrap);
                  error_msg = _route_actions.getGobalRouteError();
                } else if (!navigated && !$guard_passed.unwrap) {
                  error_msg = `${props.error.error}`;
                  error_msg += `\n this is unhandled guard error in ${local.base} step -> ${key} base route controller`;
                  error_msg += `\n you may have not called trigger.loadRoute or you've nvaigated to the same current route`;
                }
              }
              if (error_msg) {
                $loader_msg.set(error_msg);
              }
            },
          },
        });
        prev_location = { ...$location };
      }
    });
  });

  function loadRoute() {
    batch(() => {
      console.log(
        "load route base :: ",
        local.base,
        " :: exactly :: ",
        $location.pathname,
        " :: $loader_msg :: ",
        $loader_msg.unwrap,
        " :: $guard_passed :: ",
        $guard_passed.unwrap
      );
      if ($loader_msg.unwrap) {
        $loader_msg.set(undefined);
      }
      if (!$guard_passed.unwrap) {
        $guard_passed.set(true);
      }
    });
  }

  function unloadRoute() {
    if (prev_location) {
      const should_clean_up = isMatch(prev_location.pathname, route_record[local.base]);
      if (should_clean_up) {
        console.log("unload route :: ", local.base);
        // TODO: create on clean up per route base and call it here,
        // figure out the flow and model of how developer can facilitate and/or make use of this
        local.events?.onRouteCleanup?.({
          location: {
            current: $location,
            previous: prev_location,
          },
        });
      }
    }
    if ($guard_passed.unwrap) {
      $guard_passed.set(false);
    }
  }

  return (
    <Routes base={local.base}>
      <Show when={$load_route.value} fallback={null}>
        <Route path="/" component={local.layout}>
          <Show
            when={$guard_passed.value}
            // when={!$loader_msg.value && $guard_passed.value}
            fallback={
              <Route
                path="*"
                element={
                  <Atom.Loader.Platform
                    class="relative flex$col extend$ fight$"
                    title={local.guard?.title ?? "Platform Title"}
                    msg={$loader_msg.value}
                  />
                }
              />
            }
          >
            {typeof local.children === "function" ? local.children() : local.children}
            <Route
              path="*"
              element={
                <Show when={local.errorElement} fallback={<div>This page doesn't exist</div>}>
                  {local.errorElement()}
                </Show>
              }
            />
          </Show>
        </Route>
      </Show>
    </Routes>
  );
}
